import {
    ARCHIVE_MEDIA_FAILED,
    ARCHIVE_MEDIA_FULFILLED,
    ARCHIVE_MEDIA_LOADING,
    ARCHIVE_MEDIA_RESET,
    CREATE_MEDIA_FAILED,
    CREATE_MEDIA_FULFILLED,
    CREATE_MEDIA_LOADING,
    CREATE_MEDIA_RESET,
    GET_MEDIAS_FAILED,
    GET_MEDIAS_FULFILLED,
    GET_MEDIAS_LOADING,
    GET_MEDIA_DETAILS_FAILED,
    GET_MEDIA_DETAILS_FULFILLED,
    GET_MEDIA_DETAILS_LOADING,
    UPDATE_MEDIA_FAILED,
    UPDATE_MEDIA_FULFILLED,
    UPDATE_MEDIA_LOADING,
    UPDATE_MEDIA_RESET,
} from '../types';

const initialState = {
    getMediasLoading: false,
    getMediasFulfilled: false,
    getMediasFailed: false,
    getMediasResponse: {},

    createMediaLoading: false,
    createMediaFulfilled: false,
    createMediaFailed: false,
    createMediaResponse: {},

    updateMediaLoading: false,
    updateMediaFulfilled: false,
    updateMediaFailed: false,
    updateMediaResponse: {},

    archiveMediaLoading: false,
    archiveMediaFulfilled: false,
    archiveMediaFailed: false,
    archiveMediaResponse: {},

    getMediaDetailsLoading: false,
    getMediaDetailsFulfilled: false,
    getMediaDetailsFailed: false,
    getMediaDetailsResponse: {},

    changeMediaStateLoading: false,
    changeMediaStateFulfilled: false,
    changeMediaStateFailed: false,
    changeMediaStateResponse: {},
};

const MediaGroupReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_MEDIAS_LOADING:
        return {
            ...state,
            getMediasLoading: true,
            getMediasFulfilled: false,
            getMediasFailed: false,
        };

    case GET_MEDIAS_FULFILLED: {
        const tempGetMediasResponse = {...state.getMediasResponse};
        tempGetMediasResponse.offset = action?.payload?.offset;
        tempGetMediasResponse.total = action?.payload?.total;
        tempGetMediasResponse.hasMore = action?.payload?.hasMore;

        if (action?.payload?.reqOffset) {
            tempGetMediasResponse.list = (tempGetMediasResponse?.list || []).concat(action?.payload?.list || []);
        } else {
            tempGetMediasResponse.list = action?.payload?.list || [];
        }

        return {
            ...state,
            getMediasLoading: false,
            getMediasFulfilled: true,
            getMediasFailed: false,
            getMediasResponse: tempGetMediasResponse,
        };
    }

    case GET_MEDIAS_FAILED:
        return {
            ...state,
            getMediasLoading: false,
            getMediasFulfilled: false,
            getMediasFailed: true,
        };


    case CREATE_MEDIA_LOADING:
        return {
            ...state,
            createMediaLoading: true,
            createMediaFulfilled: false,
            createMediaFailed: false,
        };

    case CREATE_MEDIA_FULFILLED:
        return {
            ...state,
            createMediaLoading: false,
            createMediaFulfilled: true,
            createMediaFailed: false,
            createMediaResponse: action?.payload,
        };

    case CREATE_MEDIA_FAILED:
        return {
            ...state,
            createMediaLoading: false,
            createMediaFulfilled: false,
            createMediaFailed: true,
            createMediaResponse: action?.payload,
        };

    case CREATE_MEDIA_RESET:
        return {
            ...state,
            createMediaLoading: initialState.createMediaLoading,
            createMediaFulfilled: initialState.createMediaFulfilled,
            createMediaFailed: initialState.createMediaFailed,
            createMediaResponse: initialState.createMediaResponse
        };


    case ARCHIVE_MEDIA_LOADING:
        return {
            ...state,
            archiveMediaLoading: true,
            archiveMediaFulfilled: false,
            archiveMediaFailed: false,
        };

    case ARCHIVE_MEDIA_FULFILLED: {
        const tempGetMediasResponse = {...state.getMediasResponse};
        tempGetMediasResponse.list = tempGetMediasResponse.list || [];
        const archivedMediaIndex = tempGetMediasResponse.list.findIndex(item => (item?._id === action?.payload?._id));
        if (archivedMediaIndex >= 0) {
            tempGetMediasResponse.list.splice(archivedMediaIndex, 1);
        }

        const tempGetMediasToReviewResponse = {...state.getMediasToReviewResponse};
        tempGetMediasToReviewResponse.list = tempGetMediasToReviewResponse.list || [];
        const archivedMediaToReviewIndex = tempGetMediasToReviewResponse.list.findIndex(item => (item?._id === action?.payload?._id));
        if (archivedMediaToReviewIndex >= 0) {
            tempGetMediasToReviewResponse.list.splice(archivedMediaToReviewIndex, 1);
        }

        return {
            ...state,
            archiveMediaLoading: false,
            archiveMediaFulfilled: true,
            archiveMediaFailed: false,
            archiveMediaResponse: action?.payload,
            getMediasResponse: tempGetMediasResponse,
            getMediasToReviewResponse: tempGetMediasToReviewResponse
        };
    }

    case ARCHIVE_MEDIA_FAILED:
        return {
            ...state,
            archiveMediaLoading: false,
            archiveMediaFulfilled: false,
            archiveMediaFailed: true,
            archiveMediaResponse: action?.payload,
        };

    case ARCHIVE_MEDIA_RESET:
        return {
            ...state,
            archiveMediaLoading: initialState.archiveMediaLoading,
            archiveMediaFulfilled: initialState.archiveMediaFulfilled,
            archiveMediaFailed: initialState.archiveMediaFailed,
            archiveMediaResponse: initialState.archiveMediaResponse
        };


    case UPDATE_MEDIA_LOADING:
        return {
            ...state,
            updateMediaLoading: true,
            updateMediaFulfilled: false,
            updateMediaFailed: false,
        };

    case UPDATE_MEDIA_FULFILLED:
        return {
            ...state,
            updateMediaLoading: false,
            updateMediaFulfilled: true,
            updateMediaFailed: false,
            updateMediaResponse: action?.payload,
        };

    case UPDATE_MEDIA_FAILED:
        return {
            ...state,
            updateMediaLoading: false,
            updateMediaFulfilled: false,
            updateMediaFailed: true,
            updateMediaResponse: action?.payload,
        };

    case UPDATE_MEDIA_RESET:
        return {
            ...state,
            updateMediaLoading: initialState.updateMediaLoading,
            updateMediaFulfilled: initialState.updateMediaFulfilled,
            updateMediaFailed: initialState.updateMediaFailed,
            updateMediaResponse: initialState.updateMediaResponse
        };

    case GET_MEDIA_DETAILS_LOADING:
        return {
            ...state,
            getMediaDetailsLoading: true,
            getMediaDetailsFulfilled: false,
            getMediaDetailsFailed: false,
        };

    case GET_MEDIA_DETAILS_FULFILLED: {
        const tempGetMediaDetailsResponse = state.getMediaDetailsResponse || {};
        const _id = action?.payload?._id;
        if (_id) {
            tempGetMediaDetailsResponse[_id] = action?.payload;
        }

        return {
            ...state,
            getMediaDetailsLoading: false,
            getMediaDetailsFulfilled: true,
            getMediaDetailsFailed: false,
            getMediaDetailsResponse: tempGetMediaDetailsResponse,
        };
    }

    case GET_MEDIA_DETAILS_FAILED:
        return {
            ...state,
            getMediaDetailsLoading: false,
            getMediaDetailsFulfilled: false,
            getMediaDetailsFailed: true,
            getMediaDetailsResponse: action?.payload,
        };

    default:
        return state;
    }
};

export default MediaGroupReducer;
