import {
    ARCHIVE_STORY_FAILED,
    ARCHIVE_STORY_FULFILLED,
    ARCHIVE_STORY_LOADING,
    ARCHIVE_STORY_RESET,
    CHANGE_STORY_STATE_FAILED,
    CHANGE_STORY_STATE_FULFILLED,
    CHANGE_STORY_STATE_LOADING,
    CHANGE_STORY_STATE_RESET,
    CREATE_STORY_FAILED,
    CREATE_STORY_FULFILLED,
    CREATE_STORY_LOADING,
    CREATE_STORY_RESET,
    GET_STORIES_FAILED,
    GET_STORIES_FULFILLED,
    GET_STORIES_LOADING,
    GET_STORY_DETAILS_FAILED,
    GET_STORY_DETAILS_FULFILLED,
    GET_STORY_DETAILS_LOADING,
    UPDATE_STORY_FAILED,
    UPDATE_STORY_FULFILLED,
    UPDATE_STORY_LOADING,
    UPDATE_STORY_RESET,

    ARCHIVE_REEL_FAILED,
    ARCHIVE_REEL_FULFILLED,
    ARCHIVE_REEL_LOADING,
    ARCHIVE_REEL_RESET,
    CHANGE_REEL_STATE_FAILED,
    CHANGE_REEL_STATE_FULFILLED,
    CHANGE_REEL_STATE_LOADING,
    CHANGE_REEL_STATE_RESET,
    CREATE_REEL_FAILED,
    CREATE_REEL_FULFILLED,
    CREATE_REEL_LOADING,
    CREATE_REEL_RESET,
    GET_REELS_FAILED,
    GET_REELS_FULFILLED,
    GET_REELS_LOADING,
    GET_REEL_DETAILS_FAILED,
    GET_REEL_DETAILS_FULFILLED,
    GET_REEL_DETAILS_LOADING,
    UPDATE_REEL_FAILED,
    UPDATE_REEL_FULFILLED,
    UPDATE_REEL_LOADING,
    UPDATE_REEL_RESET,
    
} from '../types';

const initialState = {
    getStoriesLoading: false,
    getStoriesFulfilled: false,
    getStoriesFailed: false,
    getStoriesResponse: {},

    createStoryLoading: false,
    createStoryFulfilled: false,
    createStoryFailed: false,
    createStoryResponse: {},

    updateStoryLoading: false,
    updateStoryFulfilled: false,
    updateStoryFailed: false,
    updateStoryResponse: {},

    archiveStoryLoading: false,
    archiveStoryFulfilled: false,
    archiveStoryFailed: false,
    archiveStoryResponse: {},

    getStoryDetailsLoading: false,
    getStoryDetailsFulfilled: false,
    getStoryDetailsFailed: false,
    getStoryDetailsResponse: {},

    changeStoryStateLoading: false,
    changeStoryStateFulfilled: false,
    changeStoryStateFailed: false,
    changeStoryStateResponse: {},

    getReelsLoading: false,
    getReelsFulfilled: false,
    getReelsFailed: false,
    getReelsResponse: {},

    createReelLoading: false,
    createReelFulfilled: false,
    createReelFailed: false,
    createReelResponse: {},

    updateReelLoading: false,
    updateReelFulfilled: false,
    updateReelFailed: false,
    updateReelResponse: {},

    archiveReelLoading: false,
    archiveReelFulfilled: false,
    archiveReelFailed: false,
    archiveReelResponse: {},

    getReelDetailsLoading: false,
    getReelDetailsFulfilled: false,
    getReelDetailsFailed: false,
    getReelDetailsResponse: {},

    changeReelStateLoading: false,
    changeReelStateFulfilled: false,
    changeReelStateFailed: false,
    changeReelStateResponse: {},
};

const MediaGroupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_STORIES_LOADING:
            return {
                ...state,
                getStoriesLoading: true,
                getStoriesFulfilled: false,
                getStoriesFailed: false,
            };

        case GET_STORIES_FULFILLED: {
            const tempGetStoriesResponse = {...state.getStoriesResponse};
            tempGetStoriesResponse.offset = action?.payload?.offset;
            tempGetStoriesResponse.total = action?.payload?.total;
            tempGetStoriesResponse.hasMore = action?.payload?.hasMore;

            if (action?.payload?.reqOffset) {
                tempGetStoriesResponse.list = (tempGetStoriesResponse?.list || []).concat(action?.payload?.list || []);
            } else {
                tempGetStoriesResponse.list = action?.payload?.list || [];
            }

            return {
                ...state,
                getStoriesLoading: false,
                getStoriesFulfilled: true,
                getStoriesFailed: false,
                getStoriesResponse: tempGetStoriesResponse,
            };
        }

        case GET_STORIES_FAILED:
            return {
                ...state,
                getStoriesLoading: false,
                getStoriesFulfilled: false,
                getStoriesFailed: true,
            };


        case CREATE_STORY_LOADING:
            return {
                ...state,
                createStoryLoading: true,
                createStoryFulfilled: false,
                createStoryFailed: false,
            };

        case CREATE_STORY_FULFILLED:
            return {
                ...state,
                createStoryLoading: false,
                createStoryFulfilled: true,
                createStoryFailed: false,
                createStoryResponse: action?.payload,
            };

        case CREATE_STORY_FAILED:
            return {
                ...state,
                createStoryLoading: false,
                createStoryFulfilled: false,
                createStoryFailed: true,
                createStoryResponse: action?.payload,
            };

        case CREATE_STORY_RESET:
            return {
                ...state,
                createStoryLoading: initialState.createStoryLoading,
                createStoryFulfilled: initialState.createStoryFulfilled,
                createStoryFailed: initialState.createStoryFailed,
                createStoryResponse: initialState.createStoryResponse
            };


        case ARCHIVE_STORY_LOADING:
            return {
                ...state,
                archiveStoryLoading: true,
                archiveStoryFulfilled: false,
                archiveStoryFailed: false,
            };

        case ARCHIVE_STORY_FULFILLED: {
            const tempGetStoriesResponse = {...state.getStoriesResponse};
            tempGetStoriesResponse.list = tempGetStoriesResponse.list || [];
            const archivedStoryIndex = tempGetStoriesResponse.list.findIndex(item => (item?._id === action?.payload?._id));
            if (archivedStoryIndex >= 0) {
                tempGetStoriesResponse.list.splice(archivedStoryIndex, 1);
            }

            const tempGetStoriesToReviewResponse = {...state.getStoriesToReviewResponse};
            tempGetStoriesToReviewResponse.list = tempGetStoriesToReviewResponse.list || [];
            const archivedStoryToReviewIndex = tempGetStoriesToReviewResponse.list.findIndex(item => (item?._id === action?.payload?._id));
            if (archivedStoryToReviewIndex >= 0) {
                tempGetStoriesToReviewResponse.list.splice(archivedStoryToReviewIndex, 1);
            }

            return {
                ...state,
                archiveStoryLoading: false,
                archiveStoryFulfilled: true,
                archiveStoryFailed: false,
                archiveStoryResponse: action?.payload,
                getStoriesResponse: tempGetStoriesResponse,
                getStoriesToReviewResponse: tempGetStoriesToReviewResponse
            };
        }

        case ARCHIVE_STORY_FAILED:
            return {
                ...state,
                archiveStoryLoading: false,
                archiveStoryFulfilled: false,
                archiveStoryFailed: true,
                archiveStoryResponse: action?.payload,
            };

        case ARCHIVE_STORY_RESET:
            return {
                ...state,
                archiveStoryLoading: initialState.archiveStoryLoading,
                archiveStoryFulfilled: initialState.archiveStoryFulfilled,
                archiveStoryFailed: initialState.archiveStoryFailed,
                archiveStoryResponse: initialState.archiveStoryResponse
            };


        case UPDATE_STORY_LOADING:
            return {
                ...state,
                updateStoryLoading: true,
                updateStoryFulfilled: false,
                updateStoryFailed: false,
            };

        case UPDATE_STORY_FULFILLED:
            return {
                ...state,
                updateStoryLoading: false,
                updateStoryFulfilled: true,
                updateStoryFailed: false,
                updateStoryResponse: action?.payload,
            };

        case UPDATE_STORY_FAILED:
            return {
                ...state,
                updateStoryLoading: false,
                updateStoryFulfilled: false,
                updateStoryFailed: true,
                updateStoryResponse: action?.payload,
            };

        case UPDATE_STORY_RESET:
            return {
                ...state,
                updateStoryLoading: initialState.updateStoryLoading,
                updateStoryFulfilled: initialState.updateStoryFulfilled,
                updateStoryFailed: initialState.updateStoryFailed,
                updateStoryResponse: initialState.updateStoryResponse
            };

        case GET_STORY_DETAILS_LOADING:
            return {
                ...state,
                getStoryDetailsLoading: true,
                getStoryDetailsFulfilled: false,
                getStoryDetailsFailed: false,
            };

        case GET_STORY_DETAILS_FULFILLED: {
            const tempGetStoryDetailsResponse = state.getStoryDetailsResponse || {};
            const _id = action?.payload?._id;
            if (_id) {
                tempGetStoryDetailsResponse[_id] = action?.payload;
            }

            return {
                ...state,
                getStoryDetailsLoading: false,
                getStoryDetailsFulfilled: true,
                getStoryDetailsFailed: false,
                getStoryDetailsResponse: tempGetStoryDetailsResponse,
            };
        }

        case GET_STORY_DETAILS_FAILED:
            return {
                ...state,
                getStoryDetailsLoading: false,
                getStoryDetailsFulfilled: false,
                getStoryDetailsFailed: true,
                getStoryDetailsResponse: action?.payload,
            };


        case CHANGE_STORY_STATE_LOADING:
            return {
                ...state,
                changeStoryStateLoading: true,
                changeStoryStateFulfilled: false,
                changeStoryStateFailed: false,
            };

        case CHANGE_STORY_STATE_FULFILLED:
            return {
                ...state,
                changeStoryStateLoading: false,
                changeStoryStateFulfilled: true,
                changeStoryStateFailed: false,
                changeStoryStateResponse: action?.payload,
            };

        case CHANGE_STORY_STATE_FAILED:
            return {
                ...state,
                changeStoryStateLoading: false,
                changeStoryStateFulfilled: false,
                changeStoryStateFailed: true,
                changeStoryStateResponse: action?.payload,
            };

        case CHANGE_STORY_STATE_RESET:
            return {
                ...state,
                changeStoryStateLoading: initialState.changeStoryStateLoading,
                changeStoryStateFulfilled: initialState.changeStoryStateFulfilled,
                changeStoryStateFailed: initialState.changeStoryStateFailed,
                changeStoryStateResponse: initialState.changeStoryStateResponse
            };

        case GET_REELS_LOADING:
            return {
                ...state,
                getReelsLoading: true,
                getReelsFulfilled: false,
                getReelsFailed: false,
            };

        case GET_REELS_FULFILLED: {
            const tempGetReelsResponse = {...state.getReelsResponse};
            tempGetReelsResponse.offset = action?.payload?.offset;
            tempGetReelsResponse.total = action?.payload?.total;
            tempGetReelsResponse.hasMore = action?.payload?.hasMore;

            if (action?.payload?.reqOffset) {
                tempGetReelsResponse.list = (tempGetReelsResponse?.list || []).concat(action?.payload?.list || []);
            } else {
                tempGetReelsResponse.list = action?.payload?.list || [];
            }

            return {
                ...state,
                getReelsLoading: false,
                getReelsFulfilled: true,
                getReelsFailed: false,
                getReelsResponse: tempGetReelsResponse,
            };
        }

        case GET_REELS_FAILED:
            return {
                ...state,
                getReelsLoading: false,
                getReelsFulfilled: false,
                getReelsFailed: true,
            };


        case CREATE_REEL_LOADING:
            return {
                ...state,
                createReelLoading: true,
                createReelFulfilled: false,
                createReelFailed: false,
            };

        case CREATE_REEL_FULFILLED:
            return {
                ...state,
                createReelLoading: false,
                createReelFulfilled: true,
                createReelFailed: false,
                createReelResponse: action?.payload,
            };

        case CREATE_REEL_FAILED:
            return {
                ...state,
                createReelLoading: false,
                createReelFulfilled: false,
                createReelFailed: true,
                createReelResponse: action?.payload,
            };

        case CREATE_REEL_RESET:
            return {
                ...state,
                createReelLoading: initialState.createReelLoading,
                createReelFulfilled: initialState.createReelFulfilled,
                createReelFailed: initialState.createReelFailed,
                createReelResponse: initialState.createReelResponse
            };


        case ARCHIVE_REEL_LOADING:
            return {
                ...state,
                archiveReelLoading: true,
                archiveReelFulfilled: false,
                archiveReelFailed: false,
            };

        case ARCHIVE_REEL_FULFILLED: {
            const tempGetReelsResponse = {...state.getReelsResponse};
            tempGetReelsResponse.list = tempGetReelsResponse.list || [];
            const archivedReelIndex = tempGetReelsResponse.list.findIndex(item => (item?._id === action?.payload?._id));
            if (archivedReelIndex >= 0) {
                tempGetReelsResponse.list.splice(archivedReelIndex, 1);
            }

            const tempGetReelsToReviewResponse = {...state.getReelsToReviewResponse};
            tempGetReelsToReviewResponse.list = tempGetReelsToReviewResponse.list || [];
            const archivedReelToReviewIndex = tempGetReelsToReviewResponse.list.findIndex(item => (item?._id === action?.payload?._id));
            if (archivedReelToReviewIndex >= 0) {
                tempGetReelsToReviewResponse.list.splice(archivedReelToReviewIndex, 1);
            }

            return {
                ...state,
                archiveReelLoading: false,
                archiveReelFulfilled: true,
                archiveReelFailed: false,
                archiveReelResponse: action?.payload,
                getReelsResponse: tempGetReelsResponse,
                getReelsToReviewResponse: tempGetReelsToReviewResponse
            };
        }

        case ARCHIVE_REEL_FAILED:
            return {
                ...state,
                archiveReelLoading: false,
                archiveReelFulfilled: false,
                archiveReelFailed: true,
                archiveReelResponse: action?.payload,
            };

        case ARCHIVE_REEL_RESET:
            return {
                ...state,
                archiveReelLoading: initialState.archiveReelLoading,
                archiveReelFulfilled: initialState.archiveReelFulfilled,
                archiveReelFailed: initialState.archiveReelFailed,
                archiveReelResponse: initialState.archiveReelResponse
            };


        case UPDATE_REEL_LOADING:
            return {
                ...state,
                updateReelLoading: true,
                updateReelFulfilled: false,
                updateReelFailed: false,
            };

        case UPDATE_REEL_FULFILLED:
            return {
                ...state,
                updateReelLoading: false,
                updateReelFulfilled: true,
                updateReelFailed: false,
                updateReelResponse: action?.payload,
            };

        case UPDATE_REEL_FAILED:
            return {
                ...state,
                updateReelLoading: false,
                updateReelFulfilled: false,
                updateReelFailed: true,
                updateReelResponse: action?.payload,
            };

        case UPDATE_REEL_RESET:
            return {
                ...state,
                updateReelLoading: initialState.updateReelLoading,
                updateReelFulfilled: initialState.updateReelFulfilled,
                updateReelFailed: initialState.updateReelFailed,
                updateReelResponse: initialState.updateReelResponse
            };

        case GET_REEL_DETAILS_LOADING:
            return {
                ...state,
                getReelDetailsLoading: true,
                getReelDetailsFulfilled: false,
                getReelDetailsFailed: false,
            };

        case GET_REEL_DETAILS_FULFILLED: {
            const tempGetReelDetailsResponse = state.getReelDetailsResponse || {};
            const _id = action?.payload?._id;
            if (_id) {
                tempGetReelDetailsResponse[_id] = action?.payload;
            }

            return {
                ...state,
                getReelDetailsLoading: false,
                getReelDetailsFulfilled: true,
                getReelDetailsFailed: false,
                getReelDetailsResponse: tempGetReelDetailsResponse,
            };
        }

        case GET_REEL_DETAILS_FAILED:
            return {
                ...state,
                getReelDetailsLoading: false,
                getReelDetailsFulfilled: false,
                getReelDetailsFailed: true,
                getReelDetailsResponse: action?.payload,
            };


        case CHANGE_REEL_STATE_LOADING:
            return {
                ...state,
                changeReelStateLoading: true,
                changeReelStateFulfilled: false,
                changeReelStateFailed: false,
            };

        case CHANGE_REEL_STATE_FULFILLED:
            return {
                ...state,
                changeReelStateLoading: false,
                changeReelStateFulfilled: true,
                changeReelStateFailed: false,
                changeReelStateResponse: action?.payload,
            };

        case CHANGE_REEL_STATE_FAILED:
            return {
                ...state,
                changeReelStateLoading: false,
                changeReelStateFulfilled: false,
                changeReelStateFailed: true,
                changeReelStateResponse: action?.payload,
            };

        case CHANGE_REEL_STATE_RESET:
            return {
                ...state,
                changeReelStateLoading: initialState.changeReelStateLoading,
                changeReelStateFulfilled: initialState.changeReelStateFulfilled,
                changeReelStateFailed: initialState.changeReelStateFailed,
                changeReelStateResponse: initialState.changeReelStateResponse
            };
    default:
        return state;
    }
};

export default MediaGroupsReducer;
