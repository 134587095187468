// Common actions
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const TOGGLE_LOADER = 'TOGGLE_LOADER';

export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';
export const TOGGLE_TOAST = 'TOGGLE_TOAST';

export const SHOW_CONFIRMATION_MODAL = 'SHOW_CONFIRMATION_MODAL';
export const HIDE_CONFIRMATION_MODAL = 'HIDE_CONFIRMATION_MODAL';

// Mission module actions
export const GET_MISSIONS_LOADING = 'GET_MISSIONS_LOADING';
export const GET_MISSIONS_FULFILLED = 'GET_MISSIONS_FULFILLED';
export const GET_MISSIONS_FAILED = 'GET_MISSIONS_FAILED';

export const GET_MISSIONS_TO_REVIEW_LOADING = 'GET_MISSIONS_TO_REVIEW_LOADING';
export const GET_MISSIONS_TO_REVIEW_FULFILLED = 'GET_MISSIONS_TO_REVIEW_FULFILLED';
export const GET_MISSIONS_TO_REVIEW_FAILED = 'GET_MISSIONS_TO_REVIEW_FAILED';

export const CREATE_MISSION_LOADING = 'CREATE_MISSION_LOADING';
export const CREATE_MISSION_FULFILLED = 'CREATE_MISSION_FULFILLED';
export const CREATE_MISSION_FAILED = 'CREATE_MISSION_FAILED';
export const CREATE_MISSION_RESET = 'CREATE_MISSION_RESET';

export const UPDATE_MISSION_LOADING = 'UPDATE_MISSION_LOADING';
export const UPDATE_MISSION_FULFILLED = 'UPDATE_MISSION_FULFILLED';
export const UPDATE_MISSION_FAILED = 'UPDATE_MISSION_FAILED';
export const UPDATE_MISSION_RESET = 'UPDATE_MISSION_RESET';

export const ARCHIVE_MISSION_LOADING = 'ARCHIVE_MISSION_LOADING';
export const ARCHIVE_MISSION_FULFILLED = 'ARCHIVE_MISSION_FULFILLED';
export const ARCHIVE_MISSION_FAILED = 'ARCHIVE_MISSION_FAILED';
export const ARCHIVE_MISSION_RESET = 'ARCHIVE_MISSION_RESET';

export const GET_MISSION_DETAILS_LOADING = 'GET_MISSION_DETAILS_LOADING';
export const GET_MISSION_DETAILS_FULFILLED = 'GET_MISSION_DETAILS_FULFILLED';
export const GET_MISSION_DETAILS_FAILED = 'GET_MISSION_DETAILS_FAILED';

export const GET_MISSION_ARENAS_LOADING = 'GET_MISSION_ARENAS_LOADING';
export const GET_MISSION_ARENAS_FULFILLED = 'GET_MISSION_ARENAS_FULFILLED';
export const GET_MISSION_ARENAS_FAILED = 'GET_MISSION_ARENAS_FAILED';

export const GET_MISSION_LEADERBOARD_LOADING = 'GET_MISSION_LEADERBOARD_LOADING';
export const GET_MISSION_LEADERBOARD_FULFILLED = 'GET_MISSION_LEADERBOARD_FULFILLED';
export const GET_MISSION_LEADERBOARD_FAILED = 'GET_MISSION_LEADERBOARD_FAILED';

export const CHANGE_MISSION_STATE_LOADING = 'CHANGE_MISSION_STATE_LOADING';
export const CHANGE_MISSION_STATE_FULFILLED = 'CHANGE_MISSION_STATE_FULFILLED';
export const CHANGE_MISSION_STATE_FAILED = 'CHANGE_MISSION_STATE_FAILED';
export const CHANGE_MISSION_STATE_RESET = 'CHANGE_MISSION_STATE_RESET';


// Contest module actions
export const GET_CONTESTS_LOADING = 'GET_CONTESTS_LOADING';
export const GET_CONTESTS_FULFILLED = 'GET_CONTESTS_FULFILLED';
export const GET_CONTESTS_FAILED = 'GET_CONTESTS_FAILED';

export const GET_CONTESTS_TO_REVIEW_LOADING = 'GET_CONTESTS_TO_REVIEW_LOADING';
export const GET_CONTESTS_TO_REVIEW_FULFILLED = 'GET_CONTESTS_TO_REVIEW_FULFILLED';
export const GET_CONTESTS_TO_REVIEW_FAILED = 'GET_CONTESTS_TO_REVIEW_FAILED';

export const CREATE_CONTEST_LOADING = 'CREATE_CONTEST_LOADING';
export const CREATE_CONTEST_FULFILLED = 'CREATE_CONTEST_FULFILLED';
export const CREATE_CONTEST_FAILED = 'CREATE_CONTEST_FAILED';
export const CREATE_CONTEST_RESET = 'CREATE_CONTEST_RESET';

export const UPDATE_CONTEST_LOADING = 'UPDATE_CONTEST_LOADING';
export const UPDATE_CONTEST_FULFILLED = 'UPDATE_CONTEST_FULFILLED';
export const UPDATE_CONTEST_FAILED = 'UPDATE_CONTEST_FAILED';
export const UPDATE_CONTEST_RESET = 'UPDATE_CONTEST_RESET';

export const ARCHIVE_CONTEST_LOADING = 'ARCHIVE_CONTEST_LOADING';
export const ARCHIVE_CONTEST_FULFILLED = 'ARCHIVE_CONTEST_FULFILLED';
export const ARCHIVE_CONTEST_FAILED = 'ARCHIVE_CONTEST_FAILED';
export const ARCHIVE_CONTEST_RESET = 'ARCHIVE_CONTEST_RESET';

export const GET_CONTEST_DETAILS_LOADING = 'GET_CONTEST_DETAILS_LOADING';
export const GET_CONTEST_DETAILS_FULFILLED = 'GET_CONTEST_DETAILS_FULFILLED';
export const GET_CONTEST_DETAILS_FAILED = 'GET_CONTEST_DETAILS_FAILED';

export const GET_CONTEST_ARENAS_LOADING = 'GET_CONTEST_ARENAS_LOADING';
export const GET_CONTEST_ARENAS_FULFILLED = 'GET_CONTEST_ARENAS_FULFILLED';
export const GET_CONTEST_ARENAS_FAILED = 'GET_CONTEST_ARENAS_FAILED';

export const GET_CONTEST_LEADERBOARD_LOADING = 'GET_CONTEST_LEADERBOARD_LOADING';
export const GET_CONTEST_LEADERBOARD_FULFILLED = 'GET_CONTEST_LEADERBOARD_FULFILLED';
export const GET_CONTEST_LEADERBOARD_FAILED = 'GET_CONTEST_LEADERBOARD_FAILED';

export const CHANGE_CONTEST_STATE_LOADING = 'CHANGE_CONTEST_STATE_LOADING';
export const CHANGE_CONTEST_STATE_FULFILLED = 'CHANGE_CONTEST_STATE_FULFILLED';
export const CHANGE_CONTEST_STATE_FAILED = 'CHANGE_CONTEST_STATE_FAILED';
export const CHANGE_CONTEST_STATE_RESET = 'CHANGE_CONTEST_STATE_RESET';

export const REMOVE_CONTEST_PARTICIPANT_LOADING = 'REMOVE_CONTEST_PARTICIPANT_LOADING';
export const REMOVE_CONTEST_PARTICIPANT_FULFILLED = 'REMOVE_CONTEST_PARTICIPANT_FULFILLED';
export const REMOVE_CONTEST_PARTICIPANT_FAILED = 'REMOVE_CONTEST_PARTICIPANT_FAILED';
export const REMOVE_CONTEST_PARTICIPANT_RESET = 'REMOVE_CONTEST_PARTICIPANT_RESET';

export const ADD_EXCLUSION_COHORT_LOADING = 'ADD_EXCLUSION_COHORT_LOADING';
export const ADD_EXCLUSION_COHORT_FULFILLED = 'ADD_EXCLUSION_COHORT_FULFILLED';
export const ADD_EXCLUSION_COHORT_FAILED = 'ADD_EXCLUSION_COHORT_FAILED';
export const ADD_EXCLUSION_COHORT_RESET = 'ADD_EXCLUSION_COHORT_RESET';

export const SYNC_CONTEST_TEMPLATES_LOADING = 'SYNC_CONTEST_TEMPLATES_LOADING';
export const SYNC_CONTEST_TEMPLATES_FULFILLED = 'SYNC_CONTEST_TEMPLATES_FULFILLED';
export const SYNC_CONTEST_TEMPLATES_FAILED = 'SYNC_CONTEST_TEMPLATES_FAILED';
export const SYNC_CONTEST_TEMPLATES_RESET = 'SYNC_CONTEST_TEMPLATES_RESET';

// Product module actions
export const GET_PRODUCTS_LOADING = 'GET_PRODUCTS_LOADING';
export const GET_PRODUCTS_FULFILLED = 'GET_PRODUCTS_FULFILLED';
export const GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED';

export const CREATE_PRODUCT_LOADING = 'CREATE_PRODUCT_LOADING';
export const CREATE_PRODUCT_FULFILLED = 'CREATE_PRODUCT_FULFILLED';
export const CREATE_PRODUCT_FAILED = 'CREATE_PRODUCT_FAILED';
export const CREATE_PRODUCT_RESET = 'CREATE_PRODUCT_RESET';

export const UPDATE_PRODUCT_LOADING = 'UPDATE_PRODUCT_LOADING';
export const UPDATE_PRODUCT_FULFILLED = 'UPDATE_PRODUCT_FULFILLED';
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED';
export const UPDATE_PRODUCT_RESET = 'UPDATE_PRODUCT_RESET';

export const ARCHIVE_PRODUCT_LOADING = 'ARCHIVE_PRODUCT_LOADING';
export const ARCHIVE_PRODUCT_FULFILLED = 'ARCHIVE_PRODUCT_FULFILLED';
export const ARCHIVE_PRODUCT_FAILED = 'ARCHIVE_PRODUCT_FAILED';
export const ARCHIVE_PRODUCT_RESET = 'ARCHIVE_PRODUCT_RESET';

export const GET_PRODUCT_DETAILS_LOADING = 'GET_PRODUCT_DETAILS_LOADING';
export const GET_PRODUCT_DETAILS_FULFILLED = 'GET_PRODUCT_DETAILS_FULFILLED';
export const GET_PRODUCT_DETAILS_FAILED = 'GET_PRODUCT_DETAILS_FAILED';


// Activity module actions
export const GET_ACTIVITIES_LOADING = 'GET_ACTIVITIES_LOADING';
export const GET_ACTIVITIES_FULFILLED = 'GET_ACTIVITIES_FULFILLED';
export const GET_ACTIVITIES_FAILED = 'GET_ACTIVITIES_FAILED';

export const CREATE_ACTIVITY_LOADING = 'CREATE_ACTIVITY_LOADING';
export const CREATE_ACTIVITY_FULFILLED = 'CREATE_ACTIVITY_FULFILLED';
export const CREATE_ACTIVITY_FAILED = 'CREATE_ACTIVITY_FAILED';
export const CREATE_ACTIVITY_RESET = 'CREATE_ACTIVITY_RESET';

export const UPDATE_ACTIVITY_LOADING = 'UPDATE_ACTIVITY_LOADING';
export const UPDATE_ACTIVITY_FULFILLED = 'UPDATE_ACTIVITY_FULFILLED';
export const UPDATE_ACTIVITY_FAILED = 'UPDATE_ACTIVITY_FAILED';
export const UPDATE_ACTIVITY_RESET = 'UPDATE_ACTIVITY_RESET';

export const ARCHIVE_ACTIVITY_LOADING = 'ARCHIVE_ACTIVITY_LOADING';
export const ARCHIVE_ACTIVITY_FULFILLED = 'ARCHIVE_ACTIVITY_FULFILLED';
export const ARCHIVE_ACTIVITY_FAILED = 'ARCHIVE_ACTIVITY_FAILED';
export const ARCHIVE_ACTIVITY_RESET = 'ARCHIVE_ACTIVITY_RESET';

export const GET_ACTIVITY_DETAILS_LOADING = 'GET_ACTIVITY_DETAILS_LOADING';
export const GET_ACTIVITY_DETAILS_FULFILLED = 'GET_ACTIVITY_DETAILS_FULFILLED';
export const GET_ACTIVITY_DETAILS_FAILED = 'GET_ACTIVITY_DETAILS_FAILED';


// Brand module actions
export const GET_BRANDS_LOADING = 'GET_BRANDS_LOADING';
export const GET_BRANDS_FULFILLED = 'GET_BRANDS_FULFILLED';
export const GET_BRANDS_FAILED = 'GET_BRANDS_FAILED';

export const CREATE_BRAND_LOADING = 'CREATE_BRAND_LOADING';
export const CREATE_BRAND_FULFILLED = 'CREATE_BRAND_FULFILLED';
export const CREATE_BRAND_FAILED = 'CREATE_BRAND_FAILED';
export const CREATE_BRAND_RESET = 'CREATE_BRAND_RESET';

export const UPDATE_BRAND_LOADING = 'UPDATE_BRAND_LOADING';
export const UPDATE_BRAND_FULFILLED = 'UPDATE_BRAND_FULFILLED';
export const UPDATE_BRAND_FAILED = 'UPDATE_BRAND_FAILED';
export const UPDATE_BRAND_RESET = 'UPDATE_BRAND_RESET';

export const ARCHIVE_BRAND_LOADING = 'ARCHIVE_BRAND_LOADING';
export const ARCHIVE_BRAND_FULFILLED = 'ARCHIVE_BRAND_FULFILLED';
export const ARCHIVE_BRAND_FAILED = 'ARCHIVE_BRAND_FAILED';
export const ARCHIVE_BRAND_RESET = 'ARCHIVE_BRAND_RESET';

export const GET_BRAND_DETAILS_LOADING = 'GET_BRAND_DETAILS_LOADING';
export const GET_BRAND_DETAILS_FULFILLED = 'GET_BRAND_DETAILS_FULFILLED';
export const GET_BRAND_DETAILS_FAILED = 'GET_BRAND_DETAILS_FAILED';


// Offer module actions
export const GET_OFFERS_LOADING = 'GET_OFFERS_LOADING';
export const GET_OFFERS_FULFILLED = 'GET_OFFERS_FULFILLED';
export const GET_OFFERS_FAILED = 'GET_OFFERS_FAILED';

export const CREATE_OFFER_LOADING = 'CREATE_OFFER_LOADING';
export const CREATE_OFFER_FULFILLED = 'CREATE_OFFER_FULFILLED';
export const CREATE_OFFER_FAILED = 'CREATE_OFFER_FAILED';
export const CREATE_OFFER_RESET = 'CREATE_OFFER_RESET';

export const UPDATE_OFFER_LOADING = 'UPDATE_OFFER_LOADING';
export const UPDATE_OFFER_FULFILLED = 'UPDATE_OFFER_FULFILLED';
export const UPDATE_OFFER_FAILED = 'UPDATE_OFFER_FAILED';
export const UPDATE_OFFER_RESET = 'UPDATE_OFFER_RESET';

export const ARCHIVE_OFFER_LOADING = 'ARCHIVE_OFFER_LOADING';
export const ARCHIVE_OFFER_FULFILLED = 'ARCHIVE_OFFER_FULFILLED';
export const ARCHIVE_OFFER_FAILED = 'ARCHIVE_OFFER_FAILED';
export const ARCHIVE_OFFER_RESET = 'ARCHIVE_OFFER_RESET';

export const GET_OFFER_DETAILS_LOADING = 'GET_OFFER_DETAILS_LOADING';
export const GET_OFFER_DETAILS_FULFILLED = 'GET_OFFER_DETAILS_FULFILLED';
export const GET_OFFER_DETAILS_FAILED = 'GET_OFFER_DETAILS_FAILED';

// Bot module actions
export const GET_BOTS_LOADING = 'GET_BOTS_LOADING';
export const GET_BOTS_FULFILLED = 'GET_BOTS_FULFILLED';
export const GET_BOTS_FAILED = 'GET_BOTS_FAILED';

export const CREATE_BOT_LOADING = 'CREATE_BOT_LOADING';
export const CREATE_BOT_FULFILLED = 'CREATE_BOT_FULFILLED';
export const CREATE_BOT_FAILED = 'CREATE_BOT_FAILED';
export const CREATE_BOT_RESET = 'CREATE_BOT_RESET';

export const UPDATE_BOT_LOADING = 'UPDATE_BOT_LOADING';
export const UPDATE_BOT_FULFILLED = 'UPDATE_BOT_FULFILLED';
export const UPDATE_BOT_FAILED = 'UPDATE_BOT_FAILED';
export const UPDATE_BOT_RESET = 'UPDATE_BOT_RESET';

export const ARCHIVE_BOT_LOADING = 'ARCHIVE_BOT_LOADING';
export const ARCHIVE_BOT_FULFILLED = 'ARCHIVE_BOT_FULFILLED';
export const ARCHIVE_BOT_FAILED = 'ARCHIVE_BOT_FAILED';
export const ARCHIVE_BOT_RESET = 'ARCHIVE_BOT_RESET';

export const GET_BOT_DETAILS_LOADING = 'GET_BOT_DETAILS_LOADING';
export const GET_BOT_DETAILS_FULFILLED = 'GET_BOT_DETAILS_FULFILLED';
export const GET_BOT_DETAILS_FAILED = 'GET_BOT_DETAILS_FAILED';


// Featured module actions
export const GET_FEATURES_LOADING = 'GET_FEATURES_LOADING';
export const GET_FEATURES_FULFILLED = 'GET_FEATURES_FULFILLED';
export const GET_FEATURES_FAILED = 'GET_FEATURES_FAILED';

export const CREATE_FEATURE_LOADING = 'CREATE_FEATURE_LOADING';
export const CREATE_FEATURE_FULFILLED = 'CREATE_FEATURE_FULFILLED';
export const CREATE_FEATURE_FAILED = 'CREATE_FEATURE_FAILED';
export const CREATE_FEATURE_RESET = 'CREATE_FEATURE_RESET';

export const UPDATE_FEATURE_LOADING = 'UPDATE_FEATURE_LOADING';
export const UPDATE_FEATURE_FULFILLED = 'UPDATE_FEATURE_FULFILLED';
export const UPDATE_FEATURE_FAILED = 'UPDATE_FEATURE_FAILED';
export const UPDATE_FEATURE_RESET = 'UPDATE_FEATURE_RESET';

export const ARCHIVE_FEATURE_LOADING = 'ARCHIVE_FEATURE_LOADING';
export const ARCHIVE_FEATURE_FULFILLED = 'ARCHIVE_FEATURE_FULFILLED';
export const ARCHIVE_FEATURE_FAILED = 'ARCHIVE_FEATURE_FAILED';
export const ARCHIVE_FEATURE_RESET = 'ARCHIVE_FEATURE_RESET';

export const GET_FEATURE_DETAILS_LOADING = 'GET_FEATURE_DETAILS_LOADING';
export const GET_FEATURE_DETAILS_FULFILLED = 'GET_FEATURE_DETAILS_FULFILLED';
export const GET_FEATURE_DETAILS_FAILED = 'GET_FEATURE_DETAILS_FAILED';


// Campaign module actions
export const GET_CAMPAIGNS_LOADING = 'GET_CAMPAIGNS_LOADING';
export const GET_CAMPAIGNS_FULFILLED = 'GET_CAMPAIGNS_FULFILLED';
export const GET_CAMPAIGNS_FAILED = 'GET_CAMPAIGNS_FAILED';

export const GET_IN_REVIEW_CAMPAIGNS_LOADING = 'GET_IN_REVIEW_CAMPAIGNS_LOADING';
export const GET_IN_REVIEW_CAMPAIGNS_FULFILLED = 'GET_IN_REVIEW_CAMPAIGNS_FULFILLED';
export const GET_IN_REVIEW_CAMPAIGNS_FAILED = 'GET_IN_REVIEW_CAMPAIGNS_FAILED';

export const GET_NOT_IN_REVIEW_CAMPAIGNS_LOADING = 'GET_NOT_IN_REVIEW_CAMPAIGNS_LOADING';
export const GET_NOT_IN_REVIEW_CAMPAIGNS_FULFILLED = 'GET_NOT_IN_REVIEW_CAMPAIGNS_FULFILLED';
export const GET_NOT_IN_REVIEW_CAMPAIGNS_FAILED = 'GET_NOT_IN_REVIEW_CAMPAIGNS_FAILED';

export const CREATE_CAMPAIGN_LOADING = 'CREATE_CAMPAIGN_LOADING';
export const CREATE_CAMPAIGN_FULFILLED = 'CREATE_CAMPAIGN_FULFILLED';
export const CREATE_CAMPAIGN_FAILED = 'CREATE_CAMPAIGN_FAILED';
export const CREATE_CAMPAIGN_RESET = 'CREATE_CAMPAIGN_RESET';

// export const SFR_CAMPAIGN_LOADING = 'SFR_CAMPAIGN_LOADING';
// export const SFR_CAMPAIGN_FULFILLED = 'SFR_CAMPAIGN_FULFILLED';
// export const SFR_CAMPAIGN_FAILED = 'SFR_CAMPAIGN_FAILED';
// export const SFR_CAMPAIGN_RESET = 'SFR_CAMPAIGN_RESET';

// export const APPROVE_CAMPAIGN_LOADING = 'APPROVE_CAMPAIGN_LOADING';
// export const APPROVE_CAMPAIGN_FULFILLED = 'APPROVE_CAMPAIGN_FULFILLED';
// export const APPROVE_CAMPAIGN_FAILED = 'APPROVE_CAMPAIGN_FAILED';
// export const APPROVE_CAMPAIGN_RESET = 'APPROVE_CAMPAIGN_RESET';

export const UPDATE_CAMPAIGN_LOADING = 'UPDATE_CAMPAIGN_LOADING';
export const UPDATE_CAMPAIGN_FULFILLED = 'UPDATE_CAMPAIGN_FULFILLED';
export const UPDATE_CAMPAIGN_FAILED = 'UPDATE_CAMPAIGN_FAILED';
export const UPDATE_CAMPAIGN_RESET = 'UPDATE_CAMPAIGN_RESET';

// export const REQUEST_ARCHIVE_CAMPAIGN_LOADING = 'REQUEST_ARCHIVE_CAMPAIGN_LOADING';
// export const REQUEST_ARCHIVE_CAMPAIGN_FULFILLED = 'REQUEST_ARCHIVE_CAMPAIGN_FULFILLED';
// export const REQUEST_ARCHIVE_CAMPAIGN_FAILED = 'REQUEST_ARCHIVE_CAMPAIGN_FAILED';
// export const REQUEST_ARCHIVE_CAMPAIGN_RESET = 'REQUEST_ARCHIVE_CAMPAIGN_RESET';
//
// export const ARCHIVE_CAMPAIGN_LOADING = 'ARCHIVE_CAMPAIGN_LOADING';
// export const ARCHIVE_CAMPAIGN_FULFILLED = 'ARCHIVE_CAMPAIGN_FULFILLED';
// export const ARCHIVE_CAMPAIGN_FAILED = 'ARCHIVE_CAMPAIGN_FAILED';
// export const ARCHIVE_CAMPAIGN_RESET = 'ARCHIVE_CAMPAIGN_RESET';

// export const REQUEST_DEACTIVATE_CAMPAIGN_LOADING = 'REQUEST_DEACTIVATE_CAMPAIGN_LOADING';
// export const REQUEST_DEACTIVATE_CAMPAIGN_FULFILLED = 'REQUEST_DEACTIVATE_CAMPAIGN_FULFILLED';
// export const REQUEST_DEACTIVATE_CAMPAIGN_FAILED = 'REQUEST_DEACTIVATE_CAMPAIGN_FAILED';
// export const REQUEST_DEACTIVATE_CAMPAIGN_RESET = 'REQUEST_DEACTIVATE_CAMPAIGN_RESET';

// export const DEACTIVATE_CAMPAIGN_LOADING = 'DEACTIVATE_CAMPAIGN_LOADING';
// export const DEACTIVATE_CAMPAIGN_FULFILLED = 'DEACTIVATE_CAMPAIGN_FULFILLED';
// export const DEACTIVATE_CAMPAIGN_FAILED = 'DEACTIVATE_CAMPAIGN_FAILED';
// export const DEACTIVATE_CAMPAIGN_RESET = 'DEACTIVATE_CAMPAIGN_RESET';

// export const REJECT_CAMPAIGN_CHANGES_LOADING = 'REJECT_CAMPAIGN_CHANGES_LOADING';
// export const REJECT_CAMPAIGN_CHANGES_FULFILLED = 'REJECT_CAMPAIGN_CHANGES_FULFILLED';
// export const REJECT_CAMPAIGN_CHANGES_FAILED = 'REJECT_CAMPAIGN_CHANGES_FAILED';
// export const REJECT_CAMPAIGN_CHANGES_RESET = 'REJECT_CAMPAIGN_CHANGES_RESET';

// export const REVOKE_CAMPAIGN_REQUEST_LOADING = 'REVOKE_CAMPAIGN_REQUEST_LOADING';
// export const REVOKE_CAMPAIGN_REQUEST_FULFILLED = 'REVOKE_CAMPAIGN_REQUEST_FULFILLED';
// export const REVOKE_CAMPAIGN_REQUEST_FAILED = 'REVOKE_CAMPAIGN_REQUEST_FAILED';
// export const REVOKE_CAMPAIGN_REQUEST_RESET = 'REVOKE_CAMPAIGN_REQUEST_RESET';

export const GET_CAMPAIGN_DETAILS_LOADING = 'GET_CAMPAIGN_DETAILS_LOADING';
export const GET_CAMPAIGN_DETAILS_FULFILLED = 'GET_CAMPAIGN_DETAILS_FULFILLED';
export const GET_CAMPAIGN_DETAILS_FAILED = 'GET_CAMPAIGN_DETAILS_FAILED';

export const GET_ALL_ELEMENTS_LOADING = 'GET_ALL_ELEMENTS_LOADING';
export const GET_ALL_ELEMENTS_FULFILLED = 'GET_ALL_ELEMENTS_FULFILLED';
export const GET_ALL_ELEMENTS_FAILED = 'GET_ALL_ELEMENTS_FAILED';

export const GET_CAMPAIGN_CREATORS_LOADING = 'GET_CAMPAIGN_CREATORS_LOADING';
export const GET_CAMPAIGN_CREATORS_FULFILLED = 'GET_CAMPAIGN_CREATORS_FULFILLED';
export const GET_CAMPAIGN_CREATORS_FAILED = 'GET_CAMPAIGN_CREATORS_FAILED';

export const ADD_ELEMENT_LOADING = 'ADD_ELEMENT_LOADING';
export const ADD_ELEMENT_FULFILLED = 'ADD_ELEMENT_FULFILLED';
export const ADD_ELEMENT_FAILED = 'ADD_ELEMENT_FAILED';
export const ADD_ELEMENT_RESET = 'ADD_ELEMENT_RESET';

export const REMOVE_ELEMENT_LOADING = 'REMOVE_ELEMENT_LOADING';
export const REMOVE_ELEMENT_FULFILLED = 'REMOVE_ELEMENT_FULFILLED';
export const REMOVE_ELEMENT_FAILED = 'REMOVE_ELEMENT_FAILED';
export const REMOVE_ELEMENT_RESET = 'REMOVE_ELEMENT_RESET';

export const PERFORM_CAMPAIGN_OPERATION_LOADING = 'PERFORM_CAMPAIGN_OPERATION_LOADING';
export const PERFORM_CAMPAIGN_OPERATION_FULFILLED = 'PERFORM_CAMPAIGN_OPERATION_FULFILLED';
export const PERFORM_CAMPAIGN_OPERATION_FAILED = 'PERFORM_CAMPAIGN_OPERATION_FAILED';
export const PERFORM_CAMPAIGN_OPERATION_RESET = 'PERFORM_CAMPAIGN_OPERATION_RESET';

// User module actions
export const GET_USER_DETAILS_LOADING = 'GET_USER_DETAILS_LOADING';
export const GET_USER_DETAILS_FULFILLED = 'GET_USER_DETAILS_FULFILLED';
export const GET_USER_DETAILS_FAILED = 'GET_USER_DETAILS_FAILED';
export const GET_USER_DETAILS_RESET = 'GET_USER_DETAILS_RESET';

export const UPDATE_USER_DETAILS_LOADING = 'UPDATE_USER_DETAILS_LOADING';
export const UPDATE_USER_DETAILS_FULFILLED = 'UPDATE_USER_DETAILS_FULFILLED';
export const UPDATE_USER_DETAILS_FAILED = 'UPDATE_USER_DETAILS_FAILED';
export const UPDATE_USER_DETAILS_RESET = 'UPDATE_USER_DETAILS_RESET';

export const USER_LOGOUT_LOADING = 'USER_LOGOUT_LOADING';
export const USER_LOGOUT_FULFILLED = 'USER_LOGOUT_FULFILLED';
export const USER_LOGOUT_FAILED = 'USER_LOGOUT_FAILED';
export const USER_LOGOUT_RESET = 'USER_LOGOUT_RESET';

export const UPLOAD_PROFILE_PICTURE_LOADING = 'UPLOAD_PROFILE_PICTURE_LOADING';
export const UPLOAD_PROFILE_PICTURE_FULFILLED = 'UPLOAD_PROFILE_PICTURE_FULFILLED';
export const UPLOAD_PROFILE_PICTURE_FAILED = 'UPLOAD_PROFILE_PICTURE_FAILED';
export const UPLOAD_PROFILE_PICTURE_RESET = 'UPLOAD_PROFILE_PICTURE_RESET';


// Modal Dialogs module actions
export const GET_MODAL_DIALOGS_LOADING = 'GET_MODAL_DIALOGS_LOADING';
export const GET_MODAL_DIALOGS_FULFILLED = 'GET_MODAL_DIALOGS_FULFILLED';
export const GET_MODAL_DIALOGS_FAILED = 'GET_MODAL_DIALOGS_FAILED';

export const CREATE_MODAL_DIALOG_LOADING = 'CREATE_MODAL_DIALOG_LOADING';
export const CREATE_MODAL_DIALOG_FULFILLED = 'CREATE_MODAL_DIALOG_FULFILLED';
export const CREATE_MODAL_DIALOG_FAILED = 'CREATE_MODAL_DIALOG_FAILED';
export const CREATE_MODAL_DIALOG_RESET = 'CREATE_MODAL_DIALOG_RESET';

export const UPDATE_MODAL_DIALOG_LOADING = 'UPDATE_MODAL_DIALOG_LOADING';
export const UPDATE_MODAL_DIALOG_FULFILLED = 'UPDATE_MODAL_DIALOG_FULFILLED';
export const UPDATE_MODAL_DIALOG_FAILED = 'UPDATE_MODAL_DIALOG_FAILED';
export const UPDATE_MODAL_DIALOG_RESET = 'UPDATE_MODAL_DIALOG_RESET';

export const ARCHIVE_MODAL_DIALOG_LOADING = 'ARCHIVE_MODAL_DIALOG_LOADING';
export const ARCHIVE_MODAL_DIALOG_FULFILLED = 'ARCHIVE_MODAL_DIALOG_FULFILLED';
export const ARCHIVE_MODAL_DIALOG_FAILED = 'ARCHIVE_MODAL_DIALOG_FAILED';
export const ARCHIVE_MODAL_DIALOG_RESET = 'ARCHIVE_MODAL_DIALOG_RESET';

export const GET_MODAL_DIALOG_DETAILS_LOADING = 'GET_MODAL_DIALOG_DETAILS_LOADING';
export const GET_MODAL_DIALOG_DETAILS_FULFILLED = 'GET_MODAL_DIALOG_DETAILS_FULFILLED';
export const GET_MODAL_DIALOG_DETAILS_FAILED = 'GET_MODAL_DIALOG_DETAILS_FAILED';


// Modal Dialog Groups module actions
export const GET_MODAL_DIALOG_GROUPS_LOADING = 'GET_MODAL_DIALOG_GROUPS_LOADING';
export const GET_MODAL_DIALOG_GROUPS_FULFILLED = 'GET_MODAL_DIALOG_GROUPS_FULFILLED';
export const GET_MODAL_DIALOG_GROUPS_FAILED = 'GET_MODAL_DIALOG_GROUPS_FAILED';

export const CREATE_MODAL_DIALOG_GROUP_LOADING = 'CREATE_MODAL_DIALOG_GROUP_LOADING';
export const CREATE_MODAL_DIALOG_GROUP_FULFILLED = 'CREATE_MODAL_DIALOG_GROUP_FULFILLED';
export const CREATE_MODAL_DIALOG_GROUP_FAILED = 'CREATE_MODAL_DIALOG_GROUP_FAILED';
export const CREATE_MODAL_DIALOG_GROUP_RESET = 'CREATE_MODAL_DIALOG_GROUP_RESET';

export const UPDATE_MODAL_DIALOG_GROUP_LOADING = 'UPDATE_MODAL_DIALOG_GROUP_LOADING';
export const UPDATE_MODAL_DIALOG_GROUP_FULFILLED = 'UPDATE_MODAL_DIALOG_GROUP_FULFILLED';
export const UPDATE_MODAL_DIALOG_GROUP_FAILED = 'UPDATE_MODAL_DIALOG_GROUP_FAILED';
export const UPDATE_MODAL_DIALOG_GROUP_RESET = 'UPDATE_MODAL_DIALOG_GROUP_RESET';

export const ARCHIVE_MODAL_DIALOG_GROUP_LOADING = 'ARCHIVE_MODAL_DIALOG_GROUP_LOADING';
export const ARCHIVE_MODAL_DIALOG_GROUP_FULFILLED = 'ARCHIVE_MODAL_DIALOG_GROUP_FULFILLED';
export const ARCHIVE_MODAL_DIALOG_GROUP_FAILED = 'ARCHIVE_MODAL_DIALOG_GROUP_FAILED';
export const ARCHIVE_MODAL_DIALOG_GROUP_RESET = 'ARCHIVE_MODAL_DIALOG_GROUP_RESET';

export const GET_MODAL_DIALOG_GROUP_DETAILS_LOADING = 'GET_MODAL_DIALOG_GROUP_DETAILS_LOADING';
export const GET_MODAL_DIALOG_GROUP_DETAILS_FULFILLED = 'GET_MODAL_DIALOG_GROUP_DETAILS_FULFILLED';
export const GET_MODAL_DIALOG_GROUP_DETAILS_FAILED = 'GET_MODAL_DIALOG_GROUP_DETAILS_FAILED';

export const ADD_MODAL_DIALOG_TO_GROUP_LOADING = 'ADD_MODAL_DIALOG_TO_GROUP_LOADING';
export const ADD_MODAL_DIALOG_TO_GROUP_FULFILLED = 'ADD_MODAL_DIALOG_TO_GROUP_FULFILLED';
export const ADD_MODAL_DIALOG_TO_GROUP_FAILED = 'ADD_MODAL_DIALOG_TO_GROUP_FAILED';
export const ADD_MODAL_DIALOG_TO_GROUP_RESET = 'ADD_MODAL_DIALOG_TO_GROUP_RESET';

export const REMOVE_MODAL_DIALOG_FROM_GROUP_LOADING = 'REMOVE_MODAL_DIALOG_FROM_GROUP_LOADING';
export const REMOVE_MODAL_DIALOG_FROM_GROUP_FULFILLED = 'REMOVE_MODAL_DIALOG_FROM_GROUP_FULFILLED';
export const REMOVE_MODAL_DIALOG_FROM_GROUP_FAILED = 'REMOVE_MODAL_DIALOG_FROM_GROUP_FAILED';
export const REMOVE_MODAL_DIALOG_FROM_GROUP_RESET = 'REMOVE_MODAL_DIALOG_FROM_GROUP_RESET';

export const ADD_MODAL_DIALOG_GROUP_TO_CAMPAIGN_LOADING = 'ADD_MODAL_DIALOG_GROUP_TO_CAMPAIGN_LOADING';
export const ADD_MODAL_DIALOG_GROUP_TO_CAMPAIGN_FULFILLED = 'ADD_MODAL_DIALOG_GROUP_TO_CAMPAIGN_FULFILLED';
export const ADD_MODAL_DIALOG_GROUP_TO_CAMPAIGN_FAILED = 'ADD_MODAL_DIALOG_GROUP_TO_CAMPAIGN_FAILED';
export const ADD_MODAL_DIALOG_GROUP_TO_CAMPAIGN_RESET = 'ADD_MODAL_DIALOG_GROUP_TO_CAMPAIGN_RESET';

export const REMOVE_MODAL_DIALOG_GROUP_FROM_CAMPAIGN_LOADING = 'REMOVE_MODAL_DIALOG_GROUP_FROM_CAMPAIGN_LOADING';
export const REMOVE_MODAL_DIALOG_GROUP_FROM_CAMPAIGN_FULFILLED = 'REMOVE_MODAL_DIALOG_GROUP_FROM_CAMPAIGN_FULFILLED';
export const REMOVE_MODAL_DIALOG_GROUP_FROM_CAMPAIGN_FAILED = 'REMOVE_MODAL_DIALOG_GROUP_FROM_CAMPAIGN_FAILED';
export const REMOVE_MODAL_DIALOG_GROUP_FROM_CAMPAIGN_RESET = 'REMOVE_MODAL_DIALOG_GROUP_FROM_CAMPAIGN_RESET';


//Member module actions (For admins)
export const GET_MEMBERS_LOADING = 'GET_MEMBERS_LOADING';
export const GET_MEMBERS_FULFILLED = 'GET_MEMBERS_FULFILLED';
export const GET_MEMBERS_FAILED = 'GET_MEMBERS_FAILED';

export const GET_MEMBER_DETAILS_LOADING = 'GET_MEMBER_DETAILS_LOADING';
export const GET_MEMBER_DETAILS_FULFILLED = 'GET_MEMBER_DETAILS_FULFILLED';
export const GET_MEMBER_DETAILS_FAILED = 'GET_MEMBER_DETAILS_FAILED';

export const UPDATE_MEMBER_DETAILS_LOADING = 'UPDATE_MEMBER_DETAILS_LOADING';
export const UPDATE_MEMBER_DETAILS_FULFILLED = 'UPDATE_MEMBER_DETAILS_FULFILLED';
export const UPDATE_MEMBER_DETAILS_FAILED = 'UPDATE_MEMBER_DETAILS_FAILED';
export const UPDATE_MEMBER_DETAILS_RESET = 'UPDATE_MEMBER_DETAILS_RESET';

export const ARCHIVE_MEMBER_LOADING = 'ARCHIVE_MEMBER_LOADING';
export const ARCHIVE_MEMBER_FULFILLED = 'ARCHIVE_MEMBER_FULFILLED';
export const ARCHIVE_MEMBER_FAILED = 'ARCHIVE_MEMBER_FAILED';
export const ARCHIVE_MEMBER_RESET = 'ARCHIVE_MEMBER_RESET';

export const INVITE_MEMBERS_LOADING = 'INVITE_MEMBER_LOADING';
export const INVITE_MEMBERS_FULFILLED = 'INVITE_MEMBER_FULFILLED';
export const INVITE_MEMBERS_FAILED = 'INVITE_MEMBER_FAILED';
export const INVITE_MEMBERS_RESET = 'INVITE_MEMBER_RESET';


// Images module actions
export const GET_FEED_MEDIA_LOADING = 'GET_FEED_MEDIA_LOADING';
export const GET_FEED_MEDIA_FULFILLED = 'GET_FEED_MEDIA_FULFILLED';
export const GET_FEED_MEDIA_FAILED = 'GET_FEED_MEDIA_FAILED';

export const CREATE_FEED_MEDIA_LOADING = 'CREATE_FEED_MEDIA_LOADING';
export const CREATE_FEED_MEDIA_FULFILLED = 'CREATE_FEED_MEDIA_FULFILLED';
export const CREATE_FEED_MEDIA_FAILED = 'CREATE_FEED_MEDIA_FAILED';
export const CREATE_FEED_MEDIA_RESET = 'CREATE_FEED_MEDIA_RESET';

export const ARCHIVE_FEED_MEDIA_LOADING = 'ARCHIVE_FEED_MEDIA_LOADING';
export const ARCHIVE_FEED_MEDIA_FULFILLED = 'ARCHIVE_FEED_MEDIA_FULFILLED';
export const ARCHIVE_FEED_MEDIA_FAILED = 'ARCHIVE_FEED_MEDIA_FAILED';
export const ARCHIVE_FEED_MEDIA_RESET = 'ARCHIVE_FEED_MEDIA_RESET';

export const GET_MEDIA_TASKS_LOADING = 'GET_MEDIA_TASKS_LOADING';
export const GET_MEDIA_TASKS_FULFILLED = 'GET_MEDIA_TASKS_FULFILLED';
export const GET_MEDIA_TASKS_FAILED = 'GET_MEDIA_TASKS_FAILED';
export const GET_MEDIA_TASKS_RESET = 'GET_MEDIA_TASKS_RESET';

// Cohorts module actions
export const GET_COHORTS_LOADING = 'GET_COHORTS_LOADING';
export const GET_COHORTS_FULFILLED = 'GET_COHORTS_FULFILLED';
export const GET_COHORTS_FAILED = 'GET_COHORTS_FAILED';

export const SYNC_COHORTS_LOADING = 'SYNC_COHORTS_LOADING';
export const SYNC_COHORTS_FULFILLED = 'SYNC_COHORTS_FULFILLED';
export const SYNC_COHORTS_FAILED = 'SYNC_COHORTS_FAILED';


// Widgets module actions
export const GET_WIDGETS_LOADING = 'GET_WIDGETS_LOADING';
export const GET_WIDGETS_FULFILLED = 'GET_WIDGETS_FULFILLED';
export const GET_WIDGETS_FAILED = 'GET_WIDGETS_FAILED';

export const CREATE_WIDGET_LOADING = 'CREATE_WIDGET_LOADING';
export const CREATE_WIDGET_FULFILLED = 'CREATE_WIDGET_FULFILLED';
export const CREATE_WIDGET_FAILED = 'CREATE_WIDGET_FAILED';
export const CREATE_WIDGET_RESET = 'CREATE_WIDGET_RESET';

export const UPDATE_WIDGET_LOADING = 'UPDATE_WIDGET_LOADING';
export const UPDATE_WIDGET_FULFILLED = 'UPDATE_WIDGET_FULFILLED';
export const UPDATE_WIDGET_FAILED = 'UPDATE_WIDGET_FAILED';
export const UPDATE_WIDGET_RESET = 'UPDATE_WIDGET_RESET';

export const ARCHIVE_WIDGET_LOADING = 'ARCHIVE_WIDGET_LOADING';
export const ARCHIVE_WIDGET_FULFILLED = 'ARCHIVE_WIDGET_FULFILLED';
export const ARCHIVE_WIDGET_FAILED = 'ARCHIVE_WIDGET_FAILED';
export const ARCHIVE_WIDGET_RESET = 'ARCHIVE_WIDGET_RESET';

export const GET_WIDGET_DETAILS_LOADING = 'GET_WIDGET_DETAILS_LOADING';
export const GET_WIDGET_DETAILS_FULFILLED = 'GET_WIDGET_DETAILS_FULFILLED';
export const GET_WIDGET_DETAILS_FAILED = 'GET_WIDGET_DETAILS_FAILED';


// Widget Groups module actions
export const GET_WIDGET_GROUPS_LOADING = 'GET_WIDGET_GROUPS_LOADING';
export const GET_WIDGET_GROUPS_FULFILLED = 'GET_WIDGET_GROUPS_FULFILLED';
export const GET_WIDGET_GROUPS_FAILED = 'GET_WIDGET_GROUPS_FAILED';

export const CREATE_WIDGET_GROUP_LOADING = 'CREATE_WIDGET_GROUP_LOADING';
export const CREATE_WIDGET_GROUP_FULFILLED = 'CREATE_WIDGET_GROUP_FULFILLED';
export const CREATE_WIDGET_GROUP_FAILED = 'CREATE_WIDGET_GROUP_FAILED';
export const CREATE_WIDGET_GROUP_RESET = 'CREATE_WIDGET_GROUP_RESET';

export const UPDATE_WIDGET_GROUP_LOADING = 'UPDATE_WIDGET_GROUP_LOADING';
export const UPDATE_WIDGET_GROUP_FULFILLED = 'UPDATE_WIDGET_GROUP_FULFILLED';
export const UPDATE_WIDGET_GROUP_FAILED = 'UPDATE_WIDGET_GROUP_FAILED';
export const UPDATE_WIDGET_GROUP_RESET = 'UPDATE_WIDGET_GROUP_RESET';

export const ARCHIVE_WIDGET_GROUP_LOADING = 'ARCHIVE_WIDGET_GROUP_LOADING';
export const ARCHIVE_WIDGET_GROUP_FULFILLED = 'ARCHIVE_WIDGET_GROUP_FULFILLED';
export const ARCHIVE_WIDGET_GROUP_FAILED = 'ARCHIVE_WIDGET_GROUP_FAILED';
export const ARCHIVE_WIDGET_GROUP_RESET = 'ARCHIVE_WIDGET_GROUP_RESET';

export const GET_WIDGET_GROUP_DETAILS_LOADING = 'GET_WIDGET_GROUP_DETAILS_LOADING';
export const GET_WIDGET_GROUP_DETAILS_FULFILLED = 'GET_WIDGET_GROUP_DETAILS_FULFILLED';
export const GET_WIDGET_GROUP_DETAILS_FAILED = 'GET_WIDGET_GROUP_DETAILS_FAILED';


// Pages module actions
export const GET_PAGES_LOADING = 'GET_PAGES_LOADING';
export const GET_PAGES_FULFILLED = 'GET_PAGES_FULFILLED';
export const GET_PAGES_FAILED = 'GET_PAGES_FAILED';

export const CREATE_PAGE_LOADING = 'CREATE_PAGE_LOADING';
export const CREATE_PAGE_FULFILLED = 'CREATE_PAGE_FULFILLED';
export const CREATE_PAGE_FAILED = 'CREATE_PAGE_FAILED';
export const CREATE_PAGE_RESET = 'CREATE_PAGE_RESET';

export const UPDATE_PAGE_LOADING = 'UPDATE_PAGE_LOADING';
export const UPDATE_PAGE_FULFILLED = 'UPDATE_PAGE_FULFILLED';
export const UPDATE_PAGE_FAILED = 'UPDATE_PAGE_FAILED';
export const UPDATE_PAGE_RESET = 'UPDATE_PAGE_RESET';

export const ARCHIVE_PAGE_LOADING = 'ARCHIVE_PAGE_LOADING';
export const ARCHIVE_PAGE_FULFILLED = 'ARCHIVE_PAGE_FULFILLED';
export const ARCHIVE_PAGE_FAILED = 'ARCHIVE_PAGE_FAILED';
export const ARCHIVE_PAGE_RESET = 'ARCHIVE_PAGE_RESET';

export const GET_PAGE_DETAILS_LOADING = 'GET_PAGE_DETAILS_LOADING';
export const GET_PAGE_DETAILS_FULFILLED = 'GET_PAGE_DETAILS_FULFILLED';
export const GET_PAGE_DETAILS_FAILED = 'GET_PAGE_DETAILS_FAILED';


// Sections module actions
export const GET_SECTIONS_LOADING = 'GET_SECTIONS_LOADING';
export const GET_SECTIONS_FULFILLED = 'GET_SECTIONS_FULFILLED';
export const GET_SECTIONS_FAILED = 'GET_SECTIONS_FAILED';

export const CREATE_SECTION_LOADING = 'CREATE_SECTION_LOADING';
export const CREATE_SECTION_FULFILLED = 'CREATE_SECTION_FULFILLED';
export const CREATE_SECTION_FAILED = 'CREATE_SECTION_FAILED';
export const CREATE_SECTION_RESET = 'CREATE_SECTION_RESET';

export const UPDATE_SECTION_LOADING = 'UPDATE_SECTION_LOADING';
export const UPDATE_SECTION_FULFILLED = 'UPDATE_SECTION_FULFILLED';
export const UPDATE_SECTION_FAILED = 'UPDATE_SECTION_FAILED';
export const UPDATE_SECTION_RESET = 'UPDATE_SECTION_RESET';

export const ARCHIVE_SECTION_LOADING = 'ARCHIVE_SECTION_LOADING';
export const ARCHIVE_SECTION_FULFILLED = 'ARCHIVE_SECTION_FULFILLED';
export const ARCHIVE_SECTION_FAILED = 'ARCHIVE_SECTION_FAILED';
export const ARCHIVE_SECTION_RESET = 'ARCHIVE_SECTION_RESET';

export const GET_SECTION_DETAILS_LOADING = 'GET_SECTION_DETAILS_LOADING';
export const GET_SECTION_DETAILS_FULFILLED = 'GET_SECTION_DETAILS_FULFILLED';
export const GET_SECTION_DETAILS_FAILED = 'GET_SECTION_DETAILS_FAILED';


// Master of Coin module actions
export const SET_GLOBAL_ADS_LOADING = 'SET_GLOBAL_ADS_LOADING';
export const SET_GLOBAL_ADS_FULFILLED = 'SET_GLOBAL_ADS_FULFILLED';
export const SET_GLOBAL_ADS_FAILED = 'SET_GLOBAL_ADS_FAILED';
export const RESET_GLOBAL_ADS = 'RESET_GLOBAL_ADS';
export const GET_GLOBAL_ADS_LOADING = 'GET_GLOBAL_ADS_LOADING';
export const GET_GLOBAL_ADS_FULFILLED = 'GET_GLOBAL_ADS_FULFILLED';
export const GET_GLOBAL_ADS_FAILED = 'GET_GLOBAL_ADS_FAILED';


// FamHome module actions
export const SET_GLOBAL_REELS_LOADING = 'SET_GLOBAL_REELS_LOADING';
export const SET_GLOBAL_REELS_FULFILLED = 'SET_GLOBAL_REELS_FULFILLED';
export const SET_GLOBAL_REELS_FAILED = 'SET_GLOBAL_REELS_FAILED';
export const RESET_GLOBAL_REELS = 'RESET_GLOBAL_REELS';
export const GET_GLOBAL_REELS_LOADING = 'GET_GLOBAL_REELS_LOADING';
export const GET_GLOBAL_REELS_FULFILLED = 'GET_GLOBAL_REELS_FULFILLED';
export const GET_GLOBAL_REELS_FAILED = 'GET_GLOBAL_REELS_FAILED';

export const GET_STORIES_LOADING = 'GET_STORIES_LOADING';
export const GET_STORIES_FULFILLED = 'GET_STORIES_FULFILLED';
export const GET_STORIES_FAILED = 'GET_STORIES_FAILED';

export const CREATE_STORY_LOADING = 'CREATE_STORY_LOADING';
export const CREATE_STORY_FULFILLED = 'CREATE_STORY_FULFILLED';
export const CREATE_STORY_FAILED = 'CREATE_STORY_FAILED';
export const CREATE_STORY_RESET = 'CREATE_STORY_RESET';

export const UPDATE_STORY_LOADING = 'UPDATE_STORY_LOADING';
export const UPDATE_STORY_FULFILLED = 'UPDATE_STORY_FULFILLED';
export const UPDATE_STORY_FAILED = 'UPDATE_STORY_FAILED';
export const UPDATE_STORY_RESET = 'UPDATE_STORY_RESET';

export const ARCHIVE_STORY_LOADING = 'ARCHIVE_STORY_LOADING';
export const ARCHIVE_STORY_FULFILLED = 'ARCHIVE_STORY_FULFILLED';
export const ARCHIVE_STORY_FAILED = 'ARCHIVE_STORY_FAILED';
export const ARCHIVE_STORY_RESET = 'ARCHIVE_STORY_RESET';

export const GET_STORY_DETAILS_LOADING = 'GET_STORY_DETAILS_LOADING';
export const GET_STORY_DETAILS_FULFILLED = 'GET_STORY_DETAILS_FULFILLED';
export const GET_STORY_DETAILS_FAILED = 'GET_STORY_DETAILS_FAILED';

export const CHANGE_STORY_STATE_LOADING = 'CHANGE_STORY_STATE_LOADING';
export const CHANGE_STORY_STATE_FULFILLED = 'CHANGE_STORY_STATE_FULFILLED';
export const CHANGE_STORY_STATE_FAILED = 'CHANGE_STORY_STATE_FAILED';
export const CHANGE_STORY_STATE_RESET = 'CHANGE_STORY_STATE_RESET';

export const GET_REELS_LOADING = 'GET_REELS_LOADING';
export const GET_REELS_FULFILLED = 'GET_REELS_FULFILLED';
export const GET_REELS_FAILED = 'GET_REELS_FAILED';

export const CREATE_REEL_LOADING = 'CREATE_REEL_LOADING';
export const CREATE_REEL_FULFILLED = 'CREATE_REEL_FULFILLED';
export const CREATE_REEL_FAILED = 'CREATE_REEL_FAILED';
export const CREATE_REEL_RESET = 'CREATE_REEL_RESET';

export const UPDATE_REEL_LOADING = 'UPDATE_REEL_LOADING';
export const UPDATE_REEL_FULFILLED = 'UPDATE_REEL_FULFILLED';
export const UPDATE_REEL_FAILED = 'UPDATE_REEL_FAILED';
export const UPDATE_REEL_RESET = 'UPDATE_REEL_RESET';

export const ARCHIVE_REEL_LOADING = 'ARCHIVE_REEL_LOADING';
export const ARCHIVE_REEL_FULFILLED = 'ARCHIVE_REEL_FULFILLED';
export const ARCHIVE_REEL_FAILED = 'ARCHIVE_REEL_FAILED';
export const ARCHIVE_REEL_RESET = 'ARCHIVE_REEL_RESET';

export const GET_REEL_DETAILS_LOADING = 'GET_REEL_DETAILS_LOADING';
export const GET_REEL_DETAILS_FULFILLED = 'GET_REEL_DETAILS_FULFILLED';
export const GET_REEL_DETAILS_FAILED = 'GET_REEL_DETAILS_FAILED';

export const CHANGE_REEL_STATE_LOADING = 'CHANGE_REEL_STATE_LOADING';
export const CHANGE_REEL_STATE_FULFILLED = 'CHANGE_REEL_STATE_FULFILLED';
export const CHANGE_REEL_STATE_FAILED = 'CHANGE_REEL_STATE_FAILED';
export const CHANGE_REEL_STATE_RESET = 'CHANGE_REEL_STATE_RESET';

// Story/Feed Media actions

export const GET_MEDIAS_LOADING = 'GET_MEDIAS_LOADING';
export const GET_MEDIAS_FULFILLED = 'GET_MEDIAS_FULFILLED';
export const GET_MEDIAS_FAILED = 'GET_MEDIAS_FAILED';

export const CREATE_MEDIA_LOADING = 'CREATE_MEDIA_LOADING';
export const CREATE_MEDIA_FULFILLED = 'CREATE_MEDIA_FULFILLED';
export const CREATE_MEDIA_FAILED = 'CREATE_MEDIA_FAILED';
export const CREATE_MEDIA_RESET = 'CREATE_MEDIA_RESET';

export const UPDATE_MEDIA_LOADING = 'UPDATE_MEDIA_LOADING';
export const UPDATE_MEDIA_FULFILLED = 'UPDATE_MEDIA_FULFILLED';
export const UPDATE_MEDIA_FAILED = 'UPDATE_MEDIA_FAILED';
export const UPDATE_MEDIA_RESET = 'UPDATE_MEDIA_RESET';

export const ARCHIVE_MEDIA_LOADING = 'ARCHIVE_MEDIA_LOADING';
export const ARCHIVE_MEDIA_FULFILLED = 'ARCHIVE_MEDIA_FULFILLED';
export const ARCHIVE_MEDIA_FAILED = 'ARCHIVE_MEDIA_FAILED';
export const ARCHIVE_MEDIA_RESET = 'ARCHIVE_MEDIA_RESET';

export const GET_MEDIA_DETAILS_LOADING = 'GET_MEDIA_DETAILS_LOADING';
export const GET_MEDIA_DETAILS_FULFILLED = 'GET_MEDIA_DETAILS_FULFILLED';
export const GET_MEDIA_DETAILS_FAILED = 'GET_MEDIA_DETAILS_FAILED';

// Kill Switch module actions
export const GET_SWITCHES_LOADING = 'GET_SWITCHES_LOADING';
export const GET_SWITCHES_FULFILLED = 'GET_SWITCHES_FULFILLED';
export const GET_SWITCHES_FAILED = 'GET_SWITCHES_FAILED';

export const CREATE_SWITCH_LOADING = 'CREATE_SWITCH_LOADING';
export const CREATE_SWITCH_FULFILLED = 'CREATE_SWITCH_FULFILLED';
export const CREATE_SWITCH_FAILED = 'CREATE_SWITCH_FAILED';
export const CREATE_SWITCH_RESET = 'CREATE_SWITCH_RESET';

export const UPDATE_SWITCH_LOADING = 'UPDATE_SWITCH_LOADING';
export const UPDATE_SWITCH_FULFILLED = 'UPDATE_SWITCH_FULFILLED';
export const UPDATE_SWITCH_FAILED = 'UPDATE_SWITCH_FAILED';
export const UPDATE_SWITCH_RESET = 'UPDATE_SWITCH_RESET';

export const ARCHIVE_SWITCH_LOADING = 'ARCHIVE_SWITCH_LOADING';
export const ARCHIVE_SWITCH_FULFILLED = 'ARCHIVE_SWITCH_FULFILLED';
export const ARCHIVE_SWITCH_FAILED = 'ARCHIVE_SWITCH_FAILED';
export const ARCHIVE_SWITCH_RESET = 'ARCHIVE_SWITCH_RESET';

export const GET_SWITCH_DETAILS_LOADING = 'GET_SWITCH_DETAILS_LOADING';
export const GET_SWITCH_DETAILS_FULFILLED = 'GET_SWITCH_DETAILS_FULFILLED';
export const GET_SWITCH_DETAILS_FAILED = 'GET_SWITCH_DETAILS_FAILED';

export const GET_EXPERIMENTS_LOADING = 'GET_EXPERIMENTS_LOADING';
export const GET_EXPERIMENTS_FULFILLED = 'GET_EXPERIMENTS_FULFILLED';
export const GET_EXPERIMENTS_FAILED = 'GET_EXPERIMENTS_FAILED';

export const CREATE_EXPERIMENT_LOADING = 'CREATE_EXPERIMENT_LOADING';
export const CREATE_EXPERIMENT_FULFILLED = 'CREATE_EXPERIMENT_FULFILLED';
export const CREATE_EXPERIMENT_FAILED = 'CREATE_EXPERIMENT_FAILED';
export const CREATE_EXPERIMENT_RESET = 'CREATE_EXPERIMENT_RESET';

export const UPDATE_EXPERIMENT_LOADING = 'UPDATE_EXPERIMENT_LOADING';
export const UPDATE_EXPERIMENT_FULFILLED = 'UPDATE_EXPERIMENT_FULFILLED';
export const UPDATE_EXPERIMENT_FAILED = 'UPDATE_EXPERIMENT_FAILED';
export const UPDATE_EXPERIMENT_RESET = 'UPDATE_EXPERIMENT_RESET';

export const ARCHIVE_EXPERIMENT_LOADING = 'ARCHIVE_EXPERIMENT_LOADING';
export const ARCHIVE_EXPERIMENT_FULFILLED = 'ARCHIVE_EXPERIMENT_FULFILLED';
export const ARCHIVE_EXPERIMENT_FAILED = 'ARCHIVE_EXPERIMENT_FAILED';
export const ARCHIVE_EXPERIMENT_RESET = 'ARCHIVE_EXPERIMENT_RESET';

export const GET_EXPERIMENT_DETAILS_LOADING = 'GET_EXPERIMENT_DETAILS_LOADING';
export const GET_EXPERIMENT_DETAILS_FULFILLED = 'GET_EXPERIMENT_DETAILS_FULFILLED';
export const GET_EXPERIMENT_DETAILS_FAILED = 'GET_EXPERIMENT_DETAILS_FAILED';

export const GET_EXPERIMENT_GROUPS_LOADING = 'GET_EXPERIMENT_GROUPS_LOADING';
export const GET_EXPERIMENT_GROUPS_FULFILLED = 'GET_EXPERIMENT_GROUPS_FULFILLED';
export const GET_EXPERIMENT_GROUPS_FAILED = 'GET_EXPERIMENT_GROUPS_FAILED';

export const CREATE_EXPERIMENT_GROUP_LOADING = 'CREATE_EXPERIMENT_GROUP_LOADING';
export const CREATE_EXPERIMENT_GROUP_FULFILLED = 'CREATE_EXPERIMENT_GROUP_FULFILLED';
export const CREATE_EXPERIMENT_GROUP_FAILED = 'CREATE_EXPERIMENT_GROUP_FAILED';
export const CREATE_EXPERIMENT_GROUP_RESET = 'CREATE_EXPERIMENT_GROUP_RESET';

export const UPDATE_EXPERIMENT_GROUP_LOADING = 'UPDATE_EXPERIMENT_GROUP_LOADING';
export const UPDATE_EXPERIMENT_GROUP_FULFILLED = 'UPDATE_EXPERIMENT_GROUP_FULFILLED';
export const UPDATE_EXPERIMENT_GROUP_FAILED = 'UPDATE_EXPERIMENT_GROUP_FAILED';
export const UPDATE_EXPERIMENT_GROUP_RESET = 'UPDATE_EXPERIMENT_GROUP_RESET';

export const ARCHIVE_EXPERIMENT_GROUP_LOADING = 'ARCHIVE_EXPERIMENT_GROUP_LOADING';
export const ARCHIVE_EXPERIMENT_GROUP_FULFILLED = 'ARCHIVE_EXPERIMENT_GROUP_FULFILLED';
export const ARCHIVE_EXPERIMENT_GROUP_FAILED = 'ARCHIVE_EXPERIMENT_GROUP_FAILED';
export const ARCHIVE_EXPERIMENT_GROUP_RESET = 'ARCHIVE_EXPERIMENT_GROUP_RESET';

export const GET_EXPERIMENT_GROUP_DETAILS_LOADING = 'GET_EXPERIMENT_GROUP_DETAILS_LOADING';
export const GET_EXPERIMENT_GROUP_DETAILS_FULFILLED = 'GET_EXPERIMENT_GROUP_DETAILS_FULFILLED';
export const GET_EXPERIMENT_GROUP_DETAILS_FAILED = 'GET_EXPERIMENT_GROUP_DETAILS_FAILED';

//Namaspay POC member module actions (For admins)
export const GET_NAMASPAY_POC_MEMBERS_LOADING = 'GET_NAMASPAY_POC_MEMBERS_LOADING';
export const GET_NAMASPAY_POC_MEMBERS_FULFILLED = 'GET_NAMASPAY_POC_MEMBERS_FULFILLED';
export const GET_NAMASPAY_POC_MEMBERS_FAILED = 'GET_NAMASPAY_POC_MEMBERS_FAILED';

export const GET_NAMASPAY_POC_MEMBER_DETAILS_LOADING = 'GET_NAMASPAY_POC_MEMBER_DETAILS_LOADING';
export const GET_NAMASPAY_POC_MEMBER_DETAILS_FULFILLED = 'GET_NAMASPAY_POC_MEMBER_DETAILS_FULFILLED';
export const GET_NAMASPAY_POC_MEMBER_DETAILS_FAILED = 'GET_NAMASPAY_POC_MEMBER_DETAILS_FAILED';

export const UPDATE_NAMASPAY_POC_MEMBER_DETAILS_LOADING = 'UPDATE_NAMASPAY_POC_MEMBER_DETAILS_LOADING';
export const UPDATE_NAMASPAY_POC_MEMBER_DETAILS_FULFILLED = 'UPDATE_NAMASPAY_POC_MEMBER_DETAILS_FULFILLED';
export const UPDATE_NAMASPAY_POC_MEMBER_DETAILS_FAILED = 'UPDATE_NAMASPAY_POC_MEMBER_DETAILS_FAILED';
export const UPDATE_NAMASPAY_POC_MEMBER_DETAILS_RESET = 'UPDATE_NAMASPAY_POC_MEMBER_DETAILS_RESET';

export const ARCHIVE_NAMASPAY_POC_MEMBER_LOADING = 'ARCHIVE_NAMASPAY_POC_MEMBER_LOADING';
export const ARCHIVE_NAMASPAY_POC_MEMBER_FULFILLED = 'ARCHIVE_NAMASPAY_POC_MEMBER_FULFILLED';
export const ARCHIVE_NAMASPAY_POC_MEMBER_FAILED = 'ARCHIVE_NAMASPAY_POC_MEMBER_FAILED';
export const ARCHIVE_NAMASPAY_POC_MEMBER_RESET = 'ARCHIVE_NAMASPAY_POC_MEMBER_RESET';

export const INVITE_NAMASPAY_POC_MEMBERS_LOADING = 'INVITE_MEMBER_LOADING';
export const INVITE_NAMASPAY_POC_MEMBERS_FULFILLED = 'INVITE_MEMBER_FULFILLED';
export const INVITE_NAMASPAY_POC_MEMBERS_FAILED = 'INVITE_MEMBER_FAILED';
export const INVITE_NAMASPAY_POC_MEMBERS_RESET = 'INVITE_MEMBER_RESET';


//Namaspay Partner module

export const GET_NAMASPAY_PARTNERS_LOADING = 'GET_NAMASPAY_PARTNERS_LOADING';
export const GET_NAMASPAY_PARTNERS_FULFILLED = 'GET_NAMASPAY_PARTNERS_FULFILLED';
export const GET_NAMASPAY_PARTNERS_FAILED = 'GET_NAMASPAY_PARTNERS_FAILED';
export const GET_NAMASPAY_PARTNERS_RESET = 'GET_NAMASPAY_PARTNERS_RESET';

export const CREATE_NAMASPAY_PARTNER_LOADING = 'CREATE_NAMASPAY_PARTNER_LOADING';
export const CREATE_NAMASPAY_PARTNER_FULFILLED = 'CREATE_NAMASPAY_PARTNER_FULFILLED';
export const CREATE_NAMASPAY_PARTNER_FAILED = 'CREATE_NAMASPAY_PARTNER_FAILED';
export const CREATE_NAMASPAY_PARTNER_RESET = 'CREATE_NAMASPAY_PARTNER_RESET';

export const UPDATE_NAMASPAY_PARTNER_LOADING = 'UPDATE_NAMASPAY_PARTNER_LOADING';
export const UPDATE_NAMASPAY_PARTNER_FULFILLED = 'UPDATE_NAMASPAY_PARTNER_FULFILLED';
export const UPDATE_NAMASPAY_PARTNER_FAILED = 'UPDATE_NAMASPAY_PARTNER_FAILED';
export const UPDATE_NAMASPAY_PARTNER_RESET = 'UPDATE_NAMASPAY_PARTNER_RESET';

export const ARCHIVE_NAMASPAY_PARTNER_LOADING = 'ARCHIVE_NAMASPAY_PARTNER_LOADING';
export const ARCHIVE_NAMASPAY_PARTNER_FULFILLED = 'ARCHIVE_NAMASPAY_PARTNER_FULFILLED';
export const ARCHIVE_NAMASPAY_PARTNER_FAILED = 'ARCHIVE_NAMASPAY_PARTNER_FAILED';
export const ARCHIVE_NAMASPAY_PARTNER_RESET = 'ARCHIVE_NAMASPAY_PARTNER_RESET';

export const GET_NAMASPAY_PARTNER_DETAILS_LOADING = 'GET_NAMASPAY_PARTNER_DETAILS_LOADING';
export const GET_NAMASPAY_PARTNER_DETAILS_FULFILLED = 'GET_NAMASPAY_PARTNER_DETAILS_FULFILLED';
export const GET_NAMASPAY_PARTNER_DETAILS_FAILED = 'GET_NAMASPAY_PARTNER_DETAILS_FAILED';
export const GET_NAMASPAY_PARTNER_DETAILS_RESET = 'GET_NAMASPAY_PARTNER_DETAILS_RESET';

// Gift card module actions
export const SET_GLOBAL_GIFT_CARDS_LOADING = 'SET_GLOBAL_GIFT_CARDS_LOADING';
export const SET_GLOBAL_GIFT_CARDS_FULFILLED = 'SET_GLOBAL_GIFT_CARDS_FULFILLED';
export const SET_GLOBAL_GIFT_CARDS_FAILED = 'SET_GLOBAL_GIFT_CARDS_FAILED';
export const RESET_GLOBAL_GIFT_CARDS = 'RESET_GLOBAL_GIFT_CARDS';
export const GET_GLOBAL_GIFT_CARDS_LOADING = 'GET_GLOBAL_GIFT_CARDS_LOADING';
export const GET_GLOBAL_GIFT_CARDS_FULFILLED = 'GET_GLOBAL_GIFT_CARDS_FULFILLED';
export const GET_GLOBAL_GIFT_CARDS_FAILED = 'GET_GLOBAL_GIFT_CARDS_FAILED';


// Gift card module actions
export const SET_GLOBAL_PARTNERSHIPS_LOADING = 'SET_GLOBAL_PARTNERSHIPS_LOADING';
export const SET_GLOBAL_PARTNERSHIPS_FULFILLED = 'SET_GLOBAL_PARTNERSHIPS_FULFILLED';
export const SET_GLOBAL_PARTNERSHIPS_FAILED = 'SET_GLOBAL_PARTNERSHIPS_FAILED';
export const RESET_GLOBAL_PARTNERSHIPS = 'RESET_GLOBAL_PARTNERSHIPS';
export const GET_GLOBAL_PARTNERSHIPS_LOADING = 'GET_GLOBAL_PARTNERSHIPS_LOADING';
export const GET_GLOBAL_PARTNERSHIPS_FULFILLED = 'GET_GLOBAL_PARTNERSHIPS_FULFILLED';
export const GET_GLOBAL_PARTNERSHIPS_FAILED = 'GET_GLOBAL_PARTNERSHIPS_FAILED';
