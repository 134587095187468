// Libraries
import {combineReducers} from 'redux';

// Reducers
import globalReducer from './globalReducer';
import activityReducer from './activityReducer';
import brandReducer from './brandReducer';
import contestsReducer from './contestsReducer';
import productReducer from './productReducer';
import offerReducer from './offerReducer';
import campaignReducer from './campaignReducer';
import modalDialogReducer from './modalDialogReducer';
import modalDialogGroupReducer from './modalDialogGroupReducer';
import userReducer from './userReducer';
import memberReducer from './memberReducer';
import feedMediaReducer from './feedMediaReducer';
import cohortsReducer from './cohortsReducer';
import widgetsReducer from './widgetsReducer';
import widgetGroupsReducer from './widgetGroupsReducer';
import pagesReducer from './pagesReducer';
import sectionsReducer from './sectionsReducer';
import missionsReducer from './missionsReducer';
import MasterOfCoinReducer from './masterOfCoinReducer';
import switchReducer from './switchReducer';
import experimentReducer from './experimentReducer';
import experimentGroupReducer from './experimentGroupReducer';
import FamHomeReducer from './famHomeReducer';
import famBotReducer from './famBotReducer';
import featureReducer from './featureReducer';
import mediaGroupReducer from './mediaGroupsReducer';
import mediaReducer from './mediaReducer';
import namasPayPOCsReducer from './namasPayReducer';
import partnerReducer from './partnerReducer';
import allianceRavensReducer from './allianceRavensReducer';

export default combineReducers({
    globalData: globalReducer,
    missionsData: missionsReducer,
    contestsData: contestsReducer,
    productsData: productReducer,
    activitiesData: activityReducer,
    brandsData: brandReducer,
    offersData: offerReducer,
    modalDialogsData: modalDialogReducer,
    modalDialogGroupsData: modalDialogGroupReducer,
    campaignsData: campaignReducer,
    userData: userReducer,
    membersData: memberReducer,
    feedMediaData: feedMediaReducer,
    cohortsData: cohortsReducer,
    widgetsData: widgetsReducer,
    widgetGroupsData: widgetGroupsReducer,
    sectionsData: sectionsReducer,
    pagesData: pagesReducer,
    masterOfCoinData: MasterOfCoinReducer,
    famHomeData: FamHomeReducer,
    mediaGroupsData: mediaGroupReducer,
    mediaData: mediaReducer,
    switchesData: switchReducer,
    experimentsData: experimentReducer,
    experimentGroupsData: experimentGroupReducer,
    botsData: famBotReducer,
    featuresData: featureReducer,
    namasPayPOCsData: namasPayPOCsReducer,
    partnerData: partnerReducer,
    alliancesData: allianceRavensReducer,
});
